<template>
  <div>
    <Header></Header>
    <div class="cpal1">
      <img class="cpalA1" src="@/assets/cpal.png" alt="" />
      <div class="cpalB1">产品案例</div>
      <div class="cpalC1">ARTIFICIAL INTELLIGENCE</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">优秀案例展示</div>
        <div class="product">LAW ENFORCEMENT CASE</div>
      </div>
      <div class="bodyContent2" v-for="(item, index) in proList" :key="index">
        <div
          class="CPbodyContent1"
          v-for="(item1, index1) in item.dataList1"
          :key="index1"
          @click="goMore(item1)"
        >
          <div><img style="border-radius: 8px;width:100%" :src="baseURL+item1.fileSrc" alt="" /></div>
          <div class="bodyContentFont">{{item1.title}}</div>
          <div class="bodyContentFont1">
            <div class="bodyContentFont1a">{{item1.introduce}}</div>
            <div class="bodyContentFont1b" @click="goMore(item1)">
              <div>查看详情</div>
              <div> > </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
import {getProductCaseList} from "../../api/open";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      proList:[],
      baseURL: process.env.VUE_APP_BASE_API,
      dataList: [
        {
          dataList1: [
            {
                img:require('@/assets/sone.png'),
                title:'智慧工地大数据平台',
                content:'以大数据为基础，云计算及深度学习为手段，实现智慧化管理、智慧化生产、智慧化监控、智慧化服务四化目标。',
                particulars:'查看详情',
                icon:'>',
                name:'/productCase4'
            },
            {
                img:require('@/assets/stwo.png'),
                title:'执法预警监督管理平台',
                content:'该平台是为巩固完善执法规范化建设长效机制，切实提升公安机关维护社会公平正义的能力和执法公信力。',
                particulars:'查看详情',
                icon:'>',
                name:'/productCase8'
            },
            {
                img:require('@/assets/sthree.png'),
                title:'煤矿安全检测执行系统',
                content:'采用信息化手段，提高煤矿安全监察行政执法，执法监管工作水平以及执法工作的准确、及时、公正廉洁。',
                particulars:'查看详情',
                icon:'>',
                name:'/productCase2'
            },
          ],
        },
        {
          dataList1: [
            {
                img:require('@/assets/sfour.png'),
                title:'工业制冷云',
                content:'运用物联网技术，指定工业制冷解决方案，进一步提高冷库的运行效率、降低运营成本。',
                particulars:'查看详情',
                icon:'>',
                name:'/productCase7'
            },
            {
                img:require('@/assets/page智慧用电.png'),
                title:'智慧用电',
                content:'该项目实现电力集团公司基层单位项目立项、上级单位审批、回退、会签、审批流程查看等整个项目管理流程',
                particulars:'查看详情',
                icon:'>',
                name:'/productCase1'
            },
            {
                img:require('@/assets/page智慧校园.png'),
                title:'智慧校园',
                content:'实现数字化办公，对外向学生家长提供各种服务，如：消息通知、请假管理，校园活动，学生动态，教师通讯录，学生定位等各种服务。',
                particulars:'查看详情',
                icon:'>',
                name:'/productCase10'
            },
          ],
        },
        {
          dataList1: [
            {
                img:require('@/assets/page智慧农业.png'),
                title:'智慧农业',
                content:'将物联网技术运用到传统农业中去，运用传感器和软件通过移动平台或者电脑平台对农业生产进行控制，使传统农业更具有“智慧”',
                particulars:'查看详情',
                icon:'>',
                name:'/productCase6'
            },
            {
                img:require('@/assets/page智慧水利.png'),
                title:'智慧水利',
                content:'“水利工程补短板，水利工作强监管”是今后工作的总基调。当前，传统水利已难以充分满足新时代经济社会发展所需的专业化、精细化、智能化的管理要求',
                particulars:'查看详情',
                icon:'>',
                name:'/productCase5'
            },
            {
                img:require('@/assets/分包商考核管理平台1.png'),
                title:'分包商考核管理平台',
                content:'分包商考核管理平台',
                particulars:'查看详情',
                icon:'>',
                name:'/productCase9'
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      getProductCaseList({}).then(res=>{
        this.proList = res.data
      })
    },
    goMore(row) {
      this.$router.push({path:"productCase1",query:{id:row.id} });
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
}
.bodyContent2 {
  display: flex;
}
.CPbodyContent1 {
  width: 353px;
  height: 325px;
  background: #ffffff;
  box-shadow: 2px 2px 10px 0px #cae2fc;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  margin: 24px 0 0 35px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  cursor: pointer;
}
.CPbodyContent1:hover{
  transform: scale(1.1);
}
.bodyContentFont {
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  margin: 16px 0 8px 16px;
}
.bodyContentFont1 {
  margin: 0 8px 10px 16px;
  display: flex;
  justify-content: space-between;
}
.bodyContentFont1a {
  font-size: 15px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.45);
  width: 250px;
  height: 20px;
  white-space:nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}
.bodyContentFont1b {
  display: flex;
  font-size: 14px;
  color: #7d7d7d;
  cursor: pointer;
}
.cpal1 {
  position: relative;
}
.cpalA1 {
  width: 100%;
}
.cpalB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.cpalC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
</style>
